<template>
 <div>
  <div class="grid grid-cols-1 gap-4 mt-6">
   <deal
    v-for="(deal, i) in latestDeals"
    :deal="deal"
    :key="i"
    @selectVendor="selectVendor($event)"
   ></deal>
   <no-result v-if="noDealFound">
    <p class="text-12 pt-6 pb-6 opacity-50 text-credpal-700">
     No result found for this category
    </p>
   </no-result>
  </div>

  <branch
   :vendor="selectedVendor"
   v-if="selectedVendor"
   :key="componentKey"
   @success="success"
   ref="branch"
  ></branch>

  <order-success
   ref="successModal"
   message="Order Placed Successfully"
   additional-text="Click on order history to confirm your order upon delivery/pick up"
   link="order-history"
  >
   Go To Order History
  </order-success>
 </div>
</template>
<script>
 export default {
  components: {
   Deal: () => import("@/views/app/marketplace/components/Deal"),
   Branch: () => import("@/views/app/marketplace/components/branch/Index"),
   NoResult: () => import("@/views/app/marketplace/components/NoResult"),
   OrderSuccess: () => import("@/views/app/marketplace/components/OrderSuccess")
  },
  data() {
   return {
    selectedVendor: null,
    merchantDeals: this.$options.resource([]),
    componentKey: 0
   };
  },
  computed: {
   latestDeals() {
    return this.merchantDeals.data?.slice(0, 2);
   },
   noDealFound() {
    return (
     !this.merchantDeals.loading && this.merchantDeals?.data?.length === 0
    );
   }
  },
  watch: {
   "selectedVendor.business_name"() {
    this.componentKey += 1;
   }
  },
  beforeMount() {
   this.getDeals();
  },
  methods: {
   async getDeals() {
    this.merchantDeals.loading = true;
    await this.sendRequest("personal.marketplace.deals", {
     success: (response) => (this.merchantDeals.data = response.data.data),
     error: (error) => console.log(error)
    });
    this.merchantDeals.loading = false;
   },
   selectVendor(vendor) {
    this.selectedVendor = { ...vendor };
    this.$nextTick(() => {
        this.componentKey+= 1
        setTimeout(() => this.$refs.branch.open(), 300);
    })
   },
   success() {
    this.componentKey += 1;
    this.$refs.branch.close();
    this.$refs.successModal.openSuccessModal();
   }
  }
 };
</script>
